<template>
  <main id="charge">
    <section class="section-top"></section>
    <ConnectorInfoCard />
    <router-view></router-view>
  </main>
</template>
<script>
import ConnectorInfoCard from "../components/Connector/ConnectorInfoCard.vue";
export default {
  name: "Hint",
  components: { ConnectorInfoCard },
};
</script>
